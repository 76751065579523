import React, { useReducer } from 'react';
import { CategoryState } from '../../types/categoryTypes';
import categoryReducer from './category-reducer';
import CategoryContext from './category-context';
import {
	GET_CATEGORIES,
	GET_CATEGORY,
	GET_PRODUCT_VARIANTS_BY_PROVIDER,
	GET_PROVIDER_BY_LOCATION,
} from '../../helper/get-products';
import { UserGeolocation } from '../../types/userTypes';
import { ProviderDetails } from '../../types/providerTypes';
import { HomeProduct } from '../../types/productTypes';
import { DISTANCE_TO_FILTER_PROVIDER } from '../../constants/index';
import { sortProvidersByDistance } from '../../utils';

interface props {
	children: JSX.Element | JSX.Element[];
}

const CategoryProvider = ({ children }: props) => {
	const initialState: CategoryState = {
		categories: [],
		ProductDetails: [
			{
				id: '',
				sku: '',
				name: '',
				description: '',
				provider: '',
				mainImage: '',
				amount: {
					value: '',
					currency: '',
				},
				inventory: 0,
			},
		],
	};
	const [globalState, dispatch] = useReducer(categoryReducer, initialState);

	const getCategories = async (coverageId: string) => {
		const resProducts = await window.fetch(`${process.env.GATSBY_API_URL}/products/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: GET_CATEGORIES,
				variables: {
					id: coverageId,
				},
			}),
		});
		return getDataCategories(resProducts);
	};
	const getDataCategories = async (resProducts: any) => {
		try {
			const res = await resProducts.json();
			dispatch({
				type: 'GET_CATEGORIES',
				payload: res.data.getCollections,
			});
		} catch (error) {
			console.log(error);
		}
	};

	//GET CATEGORY
	const getCategory = async (collectionId: string, coverageId: string) => {
		const resProducts = await window.fetch(`${process.env.GATSBY_API_URL}/products/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: GET_CATEGORY,
				variables: {
					collectionId,
					coverageId,
				},
			}),
		});
		return getCategoryData(resProducts);
	};

	const getCategoryData = async (resProducts: any) => {
		const res = await resProducts.json();
		return res.data.getCollection;
	};

	const getProviderByLocation = async (geolocation: UserGeolocation, token: string) => {
		const resProvidersLocation = await window.fetch(`${process.env.GATSBY_API_URL}/coverage/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify({
				query: GET_PROVIDER_BY_LOCATION,
				variables: {
					geolocation,
				},
			}),
		});

		try {
			const resProviders = await resProvidersLocation.json();
			return resProviders.data.getProvidersByLocation;
		} catch (error) {
			console.log(error);
		}
	};

	const getProductVariantsByProvider = async (id: string, token: string) => {
		const resPrroducts = await window.fetch(`${process.env.GATSBY_API_URL}/products/`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
			body: JSON.stringify({
				query: GET_PRODUCT_VARIANTS_BY_PROVIDER,
				variables: {
					id,
				},
			}),
		});
		try {
			const resProviderProducts = await resPrroducts.json();
			return resProviderProducts.data.getProductVariantsByProvider;
		} catch (error) {
			console.log(error);
		}
	};

	const getHomeProducts = async (userGeolocation: UserGeolocation, variants: HomeProduct[]) => {
		if (!userGeolocation) {
			return;
		}
		const provIds = variants.map((v: HomeProduct) => {
			if (!v.provider) { console.log('Invalid provider for product variant' + v.id) }
			return v.provider?.id
		});
		const notEmptyIds = provIds.filter((id: string) => id !== '' && id !== null && id !== undefined);
		const uniqueIds = [...new Set(notEmptyIds)];
		const tmpProviders = variants.map((v: HomeProduct) => v.provider);
		const notEmptyProviders = tmpProviders.filter((p: ProviderDetails) => p !== null && p !== undefined);
		const uniqueProviders = [...new Set(notEmptyProviders)];
		const providers = uniqueIds.map((id: string) => uniqueProviders.find((p: ProviderDetails) => p.id === id));
		const respListProvider = sortProvidersByDistance(
			providers as ProviderDetails[],
			userGeolocation as unknown as { lat: number; lng: number },
		);

		const providersList = respListProvider.filter(
			(provider: ProviderDetails) =>
				provider.distance <= DISTANCE_TO_FILTER_PROVIDER && provider,
		);

		const providersId = providersList.map((provider: ProviderDetails) => provider.id);

		const homeProducts = providersId.map((id: string) => {
			return variants.filter((variant: HomeProduct) => variant.provider?.id === id);
		});

		return homeProducts;
	};

	return (
		<CategoryContext.Provider
			value={{
				categories: globalState.categories,
				productDetails: globalState.ProductDetails,
				getCategories,
				getCategory,
				getHomeProducts,
				getProductVariantsByProvider,
			}}
		>
			{children}
		</CategoryContext.Provider>
	);
};

export default CategoryProvider;
